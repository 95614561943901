import { XStack, YStack } from '@lyra/core/components'
import VideoText from '@lyra/core/components/VideoText'
import useHover from '@lyra/core/hooks/useHover'
import usePress from '@lyra/core/hooks/usePress'
import { Pathname } from '@lyra/web/constants/pages'
import { useRouter } from 'next/navigation'
import React, { useEffect } from 'react'

type TabProps = {
  path?: Pathname
  onPress?: () => void
  isSelected?: boolean
  name: string
}

type Props = {
  pages: TabProps[]
}

const Tab = ({ isSelected, name, onPress, path }: TabProps) => {
  const { prefetch, push } = useRouter()

  useEffect(() => {
    if (path) {
      prefetch(path)
    }
  }, [prefetch, path])

  const { isHovering, handleHoverIn, handleHoverOut } = useHover()
  const { isPressing, handlePressIn, handlePressOut } = usePress()

  return (
    <XStack
      alignItems="center"
      cursor="pointer"
      backgroundColor={
        isPressing ? '$invertedBg' : isSelected ? '$selectBg' : isHovering ? '$hoverBg' : undefined
      }
      onPress={onPress ? onPress : path ? () => push(path) : undefined}
      padding="$3"
      borderColor="$invertedBg"
      borderRightWidth={isSelected ? 1 : 0}
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
    >
      <VideoText
        color={isPressing ? 'inverted' : isHovering || isSelected ? 'primary' : 'secondary'}
      >
        {name}
      </VideoText>
    </XStack>
  )
}

export default function TabsVertical({ pages }: Props) {
  return (
    <YStack>
      {pages.map((props) => {
        return <Tab key={props.path} {...props} />
      })}
    </YStack>
  )
}
